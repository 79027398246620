import { Col, Form, Row, message } from "antd";
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import SNButton from "../../../components/SN/SNButton";
import SNPanel from "../../../components/SN/SNPanel";
import AccountTemplateForm from "./components/AccountTemplateForm";
import { createUser } from "../service";

const AccountForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const initValues = {
    is_active: true,
    send_login: true,
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await createUser(values);
      if (response.status === 200) {
        message.success('Account created successfully');
        history.push('/accounts/all-accounts');
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        const errorData = error.response.data;
        // Handle validation errors from API
        Object.keys(errorData).forEach(key => {
          form.setFields([
            {
              name: key,
              errors: [errorData[key]],
            },
          ]);
        });
      } else {
        message.error('Failed to create account. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <SNPanel panelTitle="Register account" style={{ marginBottom: 15 }}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        className="sn-form"
      >
        <Row gutter={16}>
          <Col span={12} >
            <AccountTemplateForm form={form} />
          

            <SNButton
              title="Cancel"
              className="sn-btn-big"
              onClick={() => history.push('/accounts/all-accounts')}
            />
            <span style={{ margin: '0 8px' }}></span>
            <SNButton
              title="Save"
              type="primary"
              className="sn-btn-big"
              loading={loading}
              onClick={form.submit}
            />
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default AccountForm;
