import Axios from "axios";
import { useEffect, useState } from "react";
import api, { url } from "../../api";

const useAccountDetails = (id: any): [any, boolean, Function] => {
  const [userData, setUserData] = useState<any>(null);
  const [willReload, setWillReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let source = Axios.CancelToken.source();
  const reload = () => {
    setUserData(null);
    setWillReload(!willReload);
  };

  const fetchDetails = () => {
    if (id) {
      setIsLoading(true);
      api
        .get(`${url.USER}${id}/`)
        .then((res) => {
          setUserData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    } else {
      setUserData(null);
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [id, willReload]);

  return [userData, isLoading, reload];
};

export const createUser = async (data: any) => {
  try {
    const response = await api.post(`${url.USER}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export default {
  useAccountDetails,
};
